import _debounce from 'lodash/debounce'
import SwiperWrapper from '@/scripts/swiper-wrapper.js'
import {
  mapResultItemToDataLayerViewItemList,
  decode,
  splitAndPushViewItemListToDataLayer
} from '../../frontend/scripts/utils'

const IN_VIEWPORT_PRODUCT_CARD_SELECTOR = '.product-card--in-viewport'
let dataLayerPushedIds = []

class CarouselSwiper extends HTMLElement {
  constructor () {
    super()
    const carousel = this.querySelector(':scope > .swiper')
    const options = this.getAttribute('data-options')
    const checkCarousel = this.dataset && this.dataset.checkCarousel ? this.dataset.checkCarousel : false
    let is_carousel = true
    const optionsParse = JSON.parse(decode(options))

    if (checkCarousel && checkCarousel === "true") {
      const breakpoints = optionsParse && optionsParse.breakpoints ? optionsParse.breakpoints : []
      const windowInnerWidth = window.innerWidth
      let slidesPerView = 0
      const swiperWrapperEl = this.querySelector('.js-swiper-wrapper')
      const countChildEl = swiperWrapperEl && swiperWrapperEl.children ? swiperWrapperEl.children.length : 0
      Object.keys(breakpoints).forEach(breakpoint => {
        if (breakpoint <= windowInnerWidth) {
          slidesPerView = Math.floor(parseInt(breakpoints[breakpoint].slidesPerView))
        }
      })

      if (countChildEl <= slidesPerView) {
        is_carousel = false
      }
    }

    if (carousel && options && is_carousel) {
      new SwiperWrapper(carousel, JSON.parse(decode(options)))
    }

    setTimeout(this.watchProductsInViewport.bind(this), 500)
    window.addEventListener('scroll', _debounce(() => {
      this.watchProductsInViewport()
    }, 500), { passive: true })
  }

  watchProductsInViewport () {
    setTimeout(() => {
      const inViewportCards = this.querySelectorAll(IN_VIEWPORT_PRODUCT_CARD_SELECTOR)
      const viewItemList = []

      if (inViewportCards && inViewportCards.length > 0) {
        inViewportCards.forEach((cardEl) => {
          const datalayerProduct = cardEl.dataset.datalayerProduct
          const productListName = cardEl.dataset.productListName ? cardEl.dataset.productListName : ''
          if (datalayerProduct) {
            const productData = JSON.parse(decode(datalayerProduct))
            const product = productData && productData.product ? productData.product : {}
  
            // We don't want to push a product more than once.
            if (product && !dataLayerPushedIds.includes(product.id)) {
              viewItemList.push(mapResultItemToDataLayerViewItemList(product, productListName))
  
              // Save pushed products
              dataLayerPushedIds.push(product.id)
            }
          }
        })
        splitAndPushViewItemListToDataLayer(viewItemList)
      }
    }, 1000)
  }
}

customElements.define('carousel-swiper', CarouselSwiper)
